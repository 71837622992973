<nav class="navbar navbar-expand-lg" id="container-navbar">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link icon-vector" [routerLink]="['/home']">
              <svg-icon src="../../../assets/icon/paper.svg"></svg-icon>
            </a>
          </li>
          <li class="nav-item"
          (mouseover)="mostrarSubtopicosMouseover()">
            <a class="nav-link">Indicação</a>
            <ul id="subtopicos"  *ngIf="mostrarSubtopicos">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/indicacao']">Validar Lotes</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/indicacoes-arquivadas']">Controle Indicações</a>
              </li>
            </ul>
          </li>
          <li *ngIf="featureRecurso" class="nav-item"
          (mouseover)="mostrarSubtopicosMouseover()">
            <a class="nav-link">Processos</a>
            <ul id="subtopicos"  *ngIf="mostrarSubtopicos">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/recurso']">Validar Processos</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/recursos-arquivados']">Processos Arquivados</a>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="navbar-nav logout">
          <li class="nav-item dropdown nav-icon">
            <a class="nav-link" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <svg-icon src="../../../assets/icon/menu-other-systems.svg"></svg-icon>
            </a>
            <ul class="dropdown-menu dropdown-sistemas " aria-labelledby="navbarDropdownMenuLink">
              <li class="header">
                <p>Outros sistemas</p>
                <hr size="10" width="100%" align="left" class="linha-horizontal">
              </li>
              <li>
                <button class="dropdown-item btn-dropdown" (click)="obterLinkProcessamento()">
                  Processamento
                  <i class="bi bi-link-45deg"></i>
                </button>
              </li>
              <li>
                <button class="dropdown-item btn-dropdown" (click)="obterLinkImpressao()">
                  Impressão
                  <i class="bi bi-link-45deg icon-button-link"></i>
                </button>
              </li>
            </ul>
          </li>
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              {{nomeUsuario}}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <svg-icon class="out" src="../../../assets/icon/locker.svg"></svg-icon>
                <li><button class="dropdown-item btn-dropdown" (click)="redefinirSenha()">Redefinir Senha</button></li>
                <svg-icon class="back" src="../../../assets/icon/out.svg"></svg-icon>
                <li><button class="dropdown-item btn-dropdown" (click)="signout()">Sair</button></li>
            </ul>
          </div>
        </ul>
      </div>
    </div>
  </nav>
