import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RecursoArquivado } from 'src/app/features/recursos/models/recursoArquivado';
import { RecursoService } from 'src/app/features/recursos/service/recurso.service';
import { UserService } from 'src/app/features/user/service/user.service';

@Component({
  selector: 'app-recursos-arquivados',
  templateUrl: './recursos-arquivados.component.html',
  styleUrls: ['./recursos-arquivados.component.scss']
})
export class RecursosArquivadosComponent implements OnInit {

  public validators: FormGroup;
  public totalizador: number;
  public recursos: RecursoArquivado[] = [];
  public page: number = 1;
  public take: 5;

  public tipoProcessos: any[] = [
    {value: "T", key: "Todos"},
    {value: "A", key: "Advertência"},
    {value: "P", key: "Defesa de autuação"},
    {value: "J", key: "Jari"},
    {value: "C", key: "Cetran"}
  ]

  public takeFilter: number[] = [
    5,
    10,
    15,
    25,
    30,
    35,
    40,
    45,
    50
  ]

  constructor( private recursoService : RecursoService,
    private route: Router, 
    private usuarioService: UserService,
    private formBuilder: FormBuilder,) {
    this.validators = this.formBuilder.group(
      {
        dataInicial: ['', Validators.required],
        dataFinal: ['', Validators.required],
        tipoProcesso: ['', Validators.required],
        termo: ['', Validators.required],
        take: [5, Validators.required]
      },
      { updateOn: 'change' }
    );
   }

  public async ngOnInit(): Promise<void> {
    await this.obterListagemArquivadas();
  }

  public retornarTipoDeProcesso(value: string) : string {
    return this.tipoProcessos.filter(x => x.value == value)[0].key;
  }

  public async onSubmit(): Promise<void> {
    this.recursos = [];
    this.page = 1;
    await this.consultarRecusosArquivados();
  }

  public async openDetalhamentoRecurso(recurso: RecursoArquivado): Promise<void> {
    const usuarioAvaliacao = await this.usuarioService.obterUsuarioPorId(recurso.usuarioAvaliacaoId);
    recurso.userAvaliacaoNome = usuarioAvaliacao.nomeUsuario;
    this.route.navigate(['/detalhe-arquivado'], 
    {queryParams: recurso});
  }

  private async obterListagemArquivadas(): Promise<void> {
    const dataFinal  = new Date();
    const dateAfter = new  Date();
    dateAfter.setDate(dateAfter.getDate() - 30);
    const dataInicial = dateAfter;
    this.validators.patchValue({
      tipoProcesso: this.tipoProcessos[0].value,
      dataInicial: dataInicial,
      dataFinal: dataFinal
    })
    await this.consultarRecusosArquivados();
  }


  private async consultarRecusosArquivados() : Promise<void> {
    const termo = this.validators.get('termo')?.value;
    const tipoProcesso = this.validators.get('tipoProcesso')?.value;
    const dataInicial =  this.validators.get('dataInicial')?.value;
    const dataFinal =  this.validators.get('dataFinal')?.value;
    this.take =  this.validators.get('take')?.value;
    const result  = await this.recursoService.getRecursosArquivados(dataInicial.toDateString(), dataFinal.toDateString(), termo, 0, 1000, tipoProcesso);
    result.recursos.forEach((x: any) => {
      const lote = new RecursoArquivado(x);
      this.recursos.push(lote);
    });
    this.totalizador = result.totalizador;
  }


}
