import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Documento } from 'src/app/shared/interfaces/documento';
import { TipoImagem } from 'src/app/shared/enums/TipoImagem';
import { MultiCheckbox } from 'src/app/shared/interfaces/multi-checkbox';
import { Buffer } from 'buffer';
import { RecursoService } from 'src/app/features/recursos/service/recurso.service';
import { ActivatedRoute } from '@angular/router';
import { RecursoArquivado } from 'src/app/features/recursos/models/recursoArquivado';

@Component({
  selector: 'app-modal-detalhe-recurso-arquivado',
  templateUrl: './modal-detalhes-arquivado.component.html',
  styleUrls: ['./modal-detalhes-arquivado.component.scss'],
})
export class ModalDetalhesArquivadoComponent implements OnInit {
  public checkboxsFile: MultiCheckbox[] = [];

  public detalheRecursoSelecionado: RecursoArquivado;
  public loteId: number;

  public imagem1: string;
  public imagem2: string;
  public imagem3: string;
  public imagem4: string;
  public imagem5: string
  public mostrarImagem1: boolean;
  public mostrarImagem2: boolean;
  public mostrarImagem3: boolean;
  public mostrarImagem4: boolean;
  public mostrarImagem5: boolean;
  public mostrarPdf1: boolean;
  public mostrarPdf2: boolean;
  public mostrarPdf3: boolean;
  public mostrarPdf4: boolean;
  public mostrarPdf5: boolean;
  public mostrarDoisArquivos:boolean

  public validators: FormGroup;
  public imagemSelect: string;
  public carregouImagens: boolean;
  public tipoImagem = TipoImagem;
  public checkDocumento: boolean = false;
  public positionImagem = 0;

  constructor(
    private route: ActivatedRoute,
    private recursoService: RecursoService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe(async (objeto: any) => {
      this.detalheRecursoSelecionado = objeto;
    });
    await this.obterImagens();
  }

  public TraducaoTipoRecurso: Record<string, string | undefined> = {
    'J': 'Jari',
    'P': 'Defesa de Autuação',
    'C': 'Cetran',
    'A': 'Advertência'
  }

  public RotacionarImagem(imagemId: string) : void {
    const containerImagem = document.getElementById(imagemId);
    const divElementImagem = containerImagem?.getElementsByTagName('div');
    const img = divElementImagem?.item(0);
    this.positionImagem = this.positionImagem + 90;
    img!.style.transform = `rotate(${this.positionImagem}deg)`;
  }

  public mostrarImagemAmpliada(arquivoVisualizacao: Documento): void {
    if(arquivoVisualizacao.typeDocumento === "application/pdf") {
      if( arquivoVisualizacao.tipoImagem === 1){
        this.mostrarPdf1 = true;
        this.mostrarImagem1 = false;
        this.imagem1 = arquivoVisualizacao.base64;
      }
      if( arquivoVisualizacao.tipoImagem === 2){
        this.mostrarPdf2 = true;
        this.mostrarImagem2 = false;
        this.imagem2 = arquivoVisualizacao.base64;
      }
      if(arquivoVisualizacao.tipoImagem === 3) {
        this.mostrarPdf3 = true;
        this.mostrarImagem3 = false;
        this.imagem3 = arquivoVisualizacao.base64;
      }
      if(arquivoVisualizacao.tipoImagem === 4) {
        this.mostrarPdf4 = true;
        this.mostrarImagem4 = false;
        this.imagem4 = arquivoVisualizacao.base64;
      }
    }
    else {
      if( arquivoVisualizacao.tipoImagem === 1){
        this.mostrarPdf1 = false;
        this.mostrarImagem1 = true;
        this.imagem1 = arquivoVisualizacao.base64;
      }
      if( arquivoVisualizacao.tipoImagem === 2){
        this.mostrarPdf2 = false;
        this.mostrarImagem2 = true;
        this.imagem2 = arquivoVisualizacao.base64;
      }
      if(arquivoVisualizacao.tipoImagem === 3) {
        this.mostrarPdf3 = false;
        this.mostrarImagem3 = true;
        this.imagem3 = arquivoVisualizacao.base64;
      }
      if( arquivoVisualizacao.tipoImagem === 4){
        this.mostrarPdf4 = false;
        this.mostrarImagem4 = true;
        this.imagem4 = arquivoVisualizacao.base64;
      }
    }
  }

  private async obterImagens(): Promise<void> {
    this.checkboxsFile = []
    await this.obterImagemDocumento(this.detalheRecursoSelecionado.recursoId,
      this.tipoImagem.FormularioIndicacao).then( resp => {
        if(resp) {
          this.checkboxsFile.push({name: this.tipoImagem.FormularioIndicacao.toString(), isCheck: false, value: resp});
        }
      })
    await this.obterImagemDocumento(this.detalheRecursoSelecionado.recursoId,
      this.tipoImagem.DocResponsabilidade).then(resp => {
        if(resp) {
          this.checkboxsFile.push({name: this.tipoImagem.DocResponsabilidade.toString(), isCheck: false, value: resp});
        }
      });
    await this.obterImagemDocumento(this.detalheRecursoSelecionado.recursoId,
      this.tipoImagem.DocumentoIdentificador).then(resp => {
        if(resp) {
          this.checkboxsFile.push({name: this.tipoImagem.DocumentoIdentificador.toString(), isCheck: false, value: resp});
        }
      });
    await this.obterImagemDocumento(this.detalheRecursoSelecionado.recursoId,
      this.tipoImagem.CNHdoInfrator).then(resp => {
        if(resp) {
          this.checkboxsFile.push({name: this.tipoImagem.CNHdoInfrator.toString(), isCheck: false, value: resp});
        }
      });
      this.carregouImagens = true;
  }

  private async obterImagemDocumento(
    recursoId: number,
    type: TipoImagem
  ): Promise<any> {
    try {
      const response = await this.recursoService.getImagenRecurso(
        recursoId,
        type
      );
      const imagemDocumentoType = response.headers.get('Content-Type');
      let imagemDocumentoBase64 = Buffer.from(response.body, 'binary').toString(
        'base64'
      );
      if (imagemDocumentoType != 'application/pdf') {
        imagemDocumentoBase64 = `data:image/png;base64,${imagemDocumentoBase64}`;
        this.imagem2 = imagemDocumentoBase64
      }
      return new Documento(imagemDocumentoBase64, imagemDocumentoType, type);
    } catch (error) {
    }
  }

  public removerImagemAmpliada(arquivoVisualizacao: Documento): void {
    if (arquivoVisualizacao.tipoImagem === 1) {
      this.mostrarPdf1 = false;
      this.mostrarImagem1 = false;
      this.imagem1 = '';
      return
    }
    if (arquivoVisualizacao.tipoImagem === 2) {
      this.mostrarPdf2 = false;
      this.mostrarImagem2 = false;
      this.imagem2 = '';
      return
    }
    if (arquivoVisualizacao.tipoImagem === 3) {
      this.mostrarPdf3 = false;
      this.mostrarImagem3 = false;
      this.imagem3 = '';
      return
    }
    if (arquivoVisualizacao.tipoImagem === 4) {
      this.mostrarPdf4 = false;
      this.mostrarImagem4 = false;
      this.imagem4 = '';
      return
    }
    if (arquivoVisualizacao.tipoImagem === 5) {
      this.mostrarPdf5 = false;
      this.mostrarImagem5 = false;
      this.imagem4 = '';
      return
    }
  }

  private limparImagens(): void {
    this.mostrarPdf2 = false;
    this.mostrarImagem2 = false;
    this.imagem2 = "";

    this.mostrarPdf3 = false;
    this.mostrarImagem3 = false;
    this.imagem3 = "";

    this.mostrarPdf1 = false;
    this.mostrarImagem1 = false;
    this.imagem1 = "";

    this.mostrarPdf4 = false;
    this.mostrarImagem4 = false;
    this.imagem4 = "";

    this.mostrarPdf5 = false;
    this.mostrarImagem5 = false;
    this.imagem5 = "";
  }

  public receberValueCheckboxFile(checkboxValue: any): void {
    this.limparImagens();
    this.checkboxsFile.forEach((check) => {
      check.value === checkboxValue ? check.isCheck = true : check.isCheck = false;

      setTimeout(() => {
        check.isCheck === true
          ? this.mostrarImagemAmpliada(
              new Documento(
                check.value.base64,
                check.value.typeDocumento,
                check.value.tipoImagem
              )
            )
          : this.removerImagemAmpliada(
              new Documento(
                check.value.base64,
                check.value.typeDocumento,
                check.value.tipoImagem
              )
            );
      }, 500);
    });
  }
}
