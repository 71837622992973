import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {

  @Input()
  public base64Pdf: string;

  @Input()
  public exibirDuasPdfs: boolean;

  public title: string;
  public src: string;
  public rotation = 0;

  public page: number = 1;
  public totalPages: number = 0;
  public isLoaded: boolean = false;
  public zoom: number = 1.0;

  constructor() { }

  ngOnInit(): void {
    this.construirPdf( this.base64Pdf )
  }


  public construirPdf(base64: string): void {
    const byteArray = new Uint8Array(atob(base64).split('').map(char => char.charCodeAt(0)));
    const blow = new Blob([byteArray], {type: 'application/pdf'});
    this.src = window.URL.createObjectURL(blow)
  }

  public downloadPdf(): void {
    const byteArray = new Uint8Array(atob(this.base64Pdf).split('').map(char => char.charCodeAt(0)));
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'documento.pdf'; // Pode personalizar o nome do arquivo aqui
    link.click();
  }
  
  public afterLoadComplete(pdfData: any): void {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  public nextPage(): void {
    this.page++;
  }

  public prevPage(): void {
    this.page--;
  }

  public zoomIn() {
    this.zoom = this.zoom + 0.25;
  }

  public zoomOut() {
    if (this.zoom > 1) {
       this.zoom = this.zoom - 0.25;
    }
  }

  public rotate(angulo: number) {
    this.rotation = this.rotation + angulo;
  }

}
