import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { LoginCallbackComponent } from './core/auth/login-callback.component';
import { HomeComponent } from './pages/home/home.component';
import { IndicacaoCondutorComponent } from './pages/indicacao-condutor/indicacao-condutor.component';
import { ProcessoDeValidacaoComponent } from './pages/indicacao-condutor/components/processo-de-validacao/processo-de-validacao.component';
import { ProcessoValidacaoRecursoComponent } from './pages/recursos/components/processo-validacao-recurso/processo-validacao-recurso.component';
import { RecursosComponent } from './pages/recursos/recursos.component';
import { RecursosArquivadosComponent } from './pages/recursos/components/recursos-arquivados/recursos-arquivados.component';
import { RecursoAuthGuard } from './core/auth/recursoAuth.guard';
import { ModalDetalhesArquivadoComponent } from './pages/recursos/components/recursos-arquivados/components/modal-detalhes-arquivados/modal-detalhes-arquivado.component';
import { IndicacoesArquivadasComponent } from './pages/indicacao-condutor/components/indicacoes-arquivadas/indicacoes-arquivadas.component';
import { ModalDetalhesIndicacaoArquivadaComponent } from './pages/indicacao-condutor/components/indicacoes-arquivadas/components/modal-detalhes-indicacao-arquivada/modal-detalhes-indicacao-arquivada.component';
import { RedefinirSenhaComponent } from './pages/redefinir-senha/redefinir-senha.component';
import { EdicaoIndicacaoComponent } from './pages/indicacao-condutor/components/indicacoes-arquivadas/edicao-indicacao/edicao-indicacao.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  {
    path: 'signin-oidc',
    component: LoginCallbackComponent,
  },
  { path: 'indicacao', component: IndicacaoCondutorComponent, canActivate: [AuthGuard]},
  { path: 'processo-de-validacao/:id', component: ProcessoDeValidacaoComponent, canActivate: [AuthGuard] },
  { path: 'recurso', component: RecursosComponent, canActivate: [AuthGuard, RecursoAuthGuard] },
  { path: 'processo-de-validacao-recurso/:id', component: ProcessoValidacaoRecursoComponent, canActivate: [AuthGuard, RecursoAuthGuard] },
  { path: 'recursos-arquivados', component: RecursosArquivadosComponent,  canActivate: [AuthGuard, RecursoAuthGuard]},
  { path: 'detalhe-arquivado', component: ModalDetalhesArquivadoComponent, canActivate: [AuthGuard, RecursoAuthGuard]},
  { path: 'indicacoes-arquivadas', component: IndicacoesArquivadasComponent,  canActivate: [AuthGuard]},
  { path: 'detalhe-indicacao-arquivada', component: ModalDetalhesIndicacaoArquivadaComponent, canActivate: [AuthGuard]},
  { path: 'redefinir-senha', component: RedefinirSenhaComponent, canActivate: [AuthGuard]},

  { path: 'edicao-indicacao/:id', component: EdicaoIndicacaoComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
