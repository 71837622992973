import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuComponent } from './shared/menu/menu.component';
import { HomeComponent } from './pages/home/home.component';
import { IndicacaoCondutorComponent } from './pages/indicacao-condutor/indicacao-condutor.component';
import { RecursosComponent } from './pages/recursos/recursos.component';
import { ModalLotesComponent } from './pages/indicacao-condutor/components/modal-lotes/modal-lotes.component';
import { ModalInformacaoLoteComponent } from './pages/indicacao-condutor/components/processo-de-validacao/component/modal-informacao-lote/modal-informacao-lote.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiCheckboxComponent } from './shared/components/multi-checkbox/multi-checkbox.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewerComponent } from './shared/components/pdf-viewer/pdf-viewer.component';
import { MultiCheckboxFileComponent } from './shared/components/multi-checkbox-file/multi-checkbox-file.component';
import { ProcessoDeValidacaoComponent } from './pages/indicacao-condutor/components/processo-de-validacao/processo-de-validacao.component';
import { BlockUIModule } from 'ng-block-ui';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthGuard } from './core/auth/auth.guard';
import { MenuLogadoComponent } from './shared/menu-logado/menu-logado.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { LoadingInterceptor } from './core/interceptors/loading.interceptor';
import { AuthInterceptor } from './core/interceptors/auth-interceptor';
import { ModalRecursoLotesComponent } from './pages/recursos/components/modal-recurso-lotes/modal-recurso-lotes.component';
import { ProcessoValidacaoRecursoComponent } from './pages/recursos/components/processo-validacao-recurso/processo-validacao-recurso.component';
import { ModalInformacaoRecursoComponent } from './pages/recursos/components/processo-validacao-recurso/components/modal-informacao-recurso/modal-informacao-recurso.component';
import { environment } from 'src/environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { RecursosArquivadosComponent } from './pages/recursos/components/recursos-arquivados/recursos-arquivados.component';
import { ModalDetalhesArquivadoComponent } from './pages/recursos/components/recursos-arquivados/components/modal-detalhes-arquivados/modal-detalhes-arquivado.component';
import { RecursoAuthGuard } from './core/auth/recursoAuth.guard';
import {NgxPaginationModule} from 'ngx-pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { IndicacoesArquivadasComponent } from './pages/indicacao-condutor/components/indicacoes-arquivadas/indicacoes-arquivadas.component';
import { ModalDetalhesIndicacaoArquivadaComponent } from './pages/indicacao-condutor/components/indicacoes-arquivadas/components/modal-detalhes-indicacao-arquivada/modal-detalhes-indicacao-arquivada.component';
import { RedefinirSenhaComponent } from './pages/redefinir-senha/redefinir-senha.component';
import { EdicaoIndicacaoComponent } from './pages/indicacao-condutor/components/indicacoes-arquivadas/edicao-indicacao/edicao-indicacao.component';
registerLocaleData(ptBr)

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    IndicacaoCondutorComponent,
    RecursosComponent,
    ModalLotesComponent,
    ModalInformacaoLoteComponent,
    MultiCheckboxComponent,
    PdfViewerComponent,
    MultiCheckboxFileComponent,
    ProcessoDeValidacaoComponent,
    MenuLogadoComponent,
    LoadingComponent,
    ModalRecursoLotesComponent,
    ProcessoValidacaoRecursoComponent,
    ModalInformacaoRecursoComponent,
    ModalDetalhesArquivadoComponent,
    RecursosArquivadosComponent,
    IndicacoesArquivadasComponent,
    RedefinirSenhaComponent,
    ModalDetalhesIndicacaoArquivadaComponent,
    EdicaoIndicacaoComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    NgxImageZoomModule,
    PdfViewerModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressBar: true,
      }),
    BsDatepickerModule.forRoot(),
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    BlockUIModule.forRoot({
      message: ''
    }),
    BlockUIModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.api],
        sendAccessToken: true
    }
    }),
    HttpClientModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    AuthGuard,
    RecursoAuthGuard
  ],
  bootstrap: [AppComponent, MenuComponent]
})
export class AppModule { }
